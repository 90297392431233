interface IconZaloProps {
  className?: string
}
export const IconZalo = ({ className }: IconZaloProps) => {
  return (
    <svg
      className={className}
      width='33'
      height='33'
      viewBox='0 0 33 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.68445 26.9219V1.58635L7.63292 0.88208C3.16132 2.67762 0 7.04497 0 12.1586V20.843C0 27.5572 5.44362 33.0008 12.1585 33.0008H20.843C24.9441 33.0008 28.5576 30.9615 30.7582 27.8504V26.9219H8.68445Z'
        fill='url(#paint0_linear_1387_32744)'
      />
      <path
        d='M20.8421 0H12.1584C10.5564 0 9.03086 0.318007 7.63058 0.88052C4.73627 3.69083 2.60547 8.27419 2.60547 12.1578C2.60547 18.0049 3.40874 21.4422 5.78854 24.7866C5.9918 25.0716 6.13505 25.4016 6.15605 25.7511C6.1928 26.3706 6.45381 27.0801 5.70529 28.0191C5.31153 28.5126 5.67904 29.2417 6.3098 29.2634C6.97282 29.2867 7.56008 29.2432 9.01886 28.6004C9.49587 28.3904 10.0494 28.3994 10.5091 28.6454C13.3937 30.1889 16.7778 30.3952 19.9736 30.3952C24.0342 30.3952 28.0843 29.4922 30.7574 27.8624C32.1651 25.8786 32.9999 23.4598 32.9999 20.8422V12.1585C33.0007 5.44362 27.557 0 20.8421 0Z'
        fill='white'
      />
      <path
        d='M27.3133 19.4997C25.418 19.4997 23.876 17.8234 23.876 15.7616C23.876 13.6998 25.418 12.0236 27.3133 12.0236C29.2086 12.0236 30.7506 13.6998 30.7506 15.7616C30.7506 17.8234 29.2086 19.4997 27.3133 19.4997ZM27.3133 14.0006C26.555 14.0006 25.9385 14.7911 25.9385 15.7616C25.9385 16.7322 26.555 17.5227 27.3133 17.5227C28.0716 17.5227 28.6881 16.7322 28.6881 15.7616C28.6881 14.7911 28.0716 14.0006 27.3133 14.0006Z'
        fill='url(#paint1_linear_1387_32744)'
      />
      <path
        d='M22.1577 19.4997C21.5877 19.4997 21.1265 19.0377 21.1265 18.4684V10.2198C21.1265 9.65049 21.5877 9.18848 22.1577 9.18848C22.7278 9.18848 23.189 9.65049 23.189 10.2198V18.4692C23.189 19.0377 22.7278 19.4997 22.1577 19.4997Z'
        fill='url(#paint2_linear_1387_32744)'
      />
      <path
        d='M19.1501 12.1961C18.7623 12.1961 18.4293 12.4128 18.2531 12.7278C17.731 12.2868 17.0928 12.0236 16.4005 12.0236C14.6447 12.0236 13.2212 13.6968 13.2212 15.7616C13.2212 17.8257 14.6447 19.4997 16.4005 19.4997C17.0928 19.4997 17.731 19.2372 18.2531 18.7955C18.4293 19.112 18.7623 19.3272 19.1501 19.3272C19.7201 19.3272 20.1814 18.8652 20.1814 18.296V13.2258C20.1814 12.6581 19.7201 12.1961 19.1501 12.1961ZM16.7005 17.5234C15.9175 17.5234 15.283 16.7352 15.283 15.7624C15.283 14.7896 15.9175 14.0014 16.7005 14.0014C17.4835 14.0014 18.1181 14.7896 18.1181 15.7624C18.1188 16.7352 17.4843 17.5234 16.7005 17.5234Z'
        fill='url(#paint3_linear_1387_32744)'
      />
      <path
        d='M12.1899 19.2417H7.1198C6.69604 19.2417 6.31503 19.007 6.12227 18.6305C5.93027 18.2532 5.96552 17.8069 6.21378 17.4649L10.1806 11.9373H7.20605C6.63604 11.9373 6.17478 11.4753 6.17478 10.906C6.17478 10.3368 6.63604 9.87476 7.20605 9.87476H12.1899C12.5762 9.87476 12.9309 10.0908 13.1072 10.435C13.2842 10.7785 13.2527 11.1933 13.0277 11.5075L8.95809 17.1792H12.1907C12.7607 17.1792 13.2219 17.6412 13.2219 18.2104C13.2212 18.7797 12.7599 19.2417 12.1899 19.2417ZM7.89306 18.6642L7.88706 18.674C7.88781 18.6702 7.89081 18.6672 7.89306 18.6642Z'
        fill='url(#paint4_linear_1387_32744)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1387_32744'
          x1='15.3791'
          y1='33.0008'
          x2='15.3791'
          y2='0.88208'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#155CDE' />
          <stop offset='0.287' stopColor='#1868E0' />
          <stop offset='0.8' stopColor='#2187E7' />
          <stop offset='1' stopColor='#2595EA' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1387_32744'
          x1='27.3133'
          y1='19.4997'
          x2='27.3133'
          y2='12.0236'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#155CDE' />
          <stop offset='0.287' stopColor='#1868E0' />
          <stop offset='0.8' stopColor='#2187E7' />
          <stop offset='1' stopColor='#2595EA' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_1387_32744'
          x1='22.1577'
          y1='19.4997'
          x2='22.1577'
          y2='9.18848'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#155CDE' />
          <stop offset='0.287' stopColor='#1868E0' />
          <stop offset='0.8' stopColor='#2187E7' />
          <stop offset='1' stopColor='#2595EA' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_1387_32744'
          x1='16.7013'
          y1='19.5005'
          x2='16.7013'
          y2='12.0243'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#155CDE' />
          <stop offset='0.287' stopColor='#1868E0' />
          <stop offset='0.8' stopColor='#2187E7' />
          <stop offset='1' stopColor='#2595EA' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_1387_32744'
          x1='9.6106'
          y1='19.2417'
          x2='9.6106'
          y2='9.87551'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#155CDE' />
          <stop offset='0.287' stopColor='#1868E0' />
          <stop offset='0.8' stopColor='#2187E7' />
          <stop offset='1' stopColor='#2595EA' />
        </linearGradient>
      </defs>
    </svg>
  )
}
