import { useNotification } from '@/containers'
import ImageOptimize from '../ImageOptimize'

export const HeaderLogo = () => {
  const { systemConfig } = useNotification()
  return (
    <div className='inline-flex'>
      <button className='flex gap-1 items-center outline-0'>
        <ImageOptimize
          loading='eager'
          priority
          className='h-full w-auto'
          alt={systemConfig?.websiteBrandName || 'Logo'}
          src={systemConfig?.companyLogoHeaderMobile || ''}
          width={84}
          height={60}
        />
      </button>
    </div>
  )
}
