interface IconMessengerProps {
  className?: string
}
export const IconMessenger = ({ className }: IconMessengerProps) => {
  return (
    <svg
      className={className}
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='30' cy='30' r='30' fill='url(#paint0_linear_1387_32751)' />
      <path
        d='M30.4983 12.0034C21.0414 11.8169 13.2159 19.3163 13 28.7726C13.0247 33.5862 15.1561 38.1476 18.8327 41.2547V46.2709C18.8327 46.6735 19.1592 47 19.5619 47C19.6985 47 19.8324 46.9616 19.9483 46.8892L24.0122 44.3505C26.0828 45.1424 28.2815 45.5463 30.4983 45.5418C39.9552 45.7283 47.7807 38.2289 47.9966 28.7726C47.7807 19.3163 39.9552 11.8169 30.4983 12.0034Z'
        fill='white'
      />
      <path
        d='M42.0182 23.9606C41.8013 23.6698 41.4035 23.5828 41.085 23.7564L33.5024 27.8904L28.7852 23.8454C28.496 23.5974 28.0645 23.6139 27.7951 23.8833L19.046 32.6325C18.7621 32.918 18.7635 33.3797 19.0491 33.6636C19.2775 33.8906 19.6277 33.9409 19.9107 33.7873L27.4933 29.6534L32.2149 33.6998C32.5042 33.9478 32.9356 33.9314 33.205 33.6619L41.9541 24.9128C42.2091 24.6554 42.2364 24.2499 42.0182 23.9606Z'
        fill='#0068FF'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1387_32751'
          x1='46'
          y1='2.62757e-07'
          x2='11.5'
          y2='57.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#DC579E' />
          <stop offset='0.520833' stopColor='#784AFE' />
          <stop offset='0.959079' stopColor='#1785FF' />
        </linearGradient>
      </defs>
    </svg>
  )
}
