interface IconChatProps {
  className?: string
}
export const IconChat = ({ className }: IconChatProps) => {
  return (
    <svg
      className={className}
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='30' cy='30' r='30' fill='#FF0000' />
      <g clipPath='url(#clip0_1387_31854)'>
        <path
          d='M40.8218 45.0445C41.139 45.0445 41.4521 45.1652 41.6902 45.3952C43.2124 46.8651 45.4329 47.3545 47.4068 46.7419C46.1364 44.0954 45.9604 41.0634 46.9448 38.2561C48.1666 34.7633 48.4727 31.2624 47.0823 27.7512C45.8608 24.6668 43.5035 22.1041 40.5201 20.6428C40.7037 21.6213 40.7962 22.6183 40.7962 23.6258C40.7962 27.9023 39.1518 31.9245 36.1659 34.9516C33.1797 37.979 29.1768 39.6812 24.8946 39.7449C24.099 39.7574 23.307 39.7106 22.5239 39.6073C24.8774 43.7326 29.3159 46.4193 34.1933 46.4916C36.3269 46.5246 38.3756 46.0776 40.2835 45.1665C40.4553 45.0843 40.6393 45.0445 40.8218 45.0445Z'
          fill='white'
        />
        <path
          d='M24.9297 10.0028C17.3332 9.852 11.0184 15.9975 11 23.5924C10.9956 25.448 11.3578 27.247 12.0767 28.9399C12.0871 28.9645 12.0969 28.9894 12.1056 29.0147C13.0901 31.822 12.9141 34.8539 11.6437 37.5005C13.6178 38.1129 15.838 37.6237 17.3603 36.1537C17.7353 35.7914 18.2965 35.7002 18.767 35.9251C20.6746 36.8362 22.7235 37.2846 24.8571 37.2503C32.3942 37.1384 38.2982 31.1538 38.2981 23.6259C38.2982 16.2623 32.3011 10.151 24.9297 10.0028ZM17.3685 25.6609C16.2771 25.6609 15.3923 24.7776 15.3923 23.688C15.3923 22.5984 16.2771 21.7151 17.3685 21.7151C18.4599 21.7151 19.3447 22.5984 19.3447 23.688C19.3446 24.7776 18.4599 25.6609 17.3685 25.6609ZM24.6491 25.6609C23.5577 25.6609 22.6729 24.7776 22.6729 23.688C22.6729 22.5984 23.5577 21.7151 24.6491 21.7151C25.7405 21.7151 26.6253 22.5984 26.6253 23.688C26.6253 24.7776 25.7405 25.6609 24.6491 25.6609ZM31.9298 25.6609C30.8384 25.6609 29.9536 24.7776 29.9536 23.688C29.9536 22.5984 30.8384 21.7151 31.9298 21.7151C33.0212 21.7151 33.906 22.5984 33.906 23.688C33.906 24.7776 33.0212 25.6609 31.9298 25.6609Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_1387_31854'>
          <rect
            width='37'
            height='37'
            fill='white'
            transform='translate(11 10)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
