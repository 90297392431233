/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNotification } from '@/containers'
import { useClickOutsideComponent } from '@/utils/useClickOutsideComponent'
import DrawChat from '../DrawChat'
import { IconChat } from '../Icon/IconChat'
import { IconChatClose } from '../Icon/IconChatClose'
import { IconHotLine } from '../Icon/IconHotLine'
import { IconMessenger } from '../Icon/IconMessenger'
import { IconZalo } from '../Icon/IconZalo'

export function PopupChat() {
  const { systemConfig } = useNotification()
  const { ref, isComponentVisible, setIsComponentVisible } =
    useClickOutsideComponent(false)
  return (
    <>
      <DrawChat
        refer={ref || null}
        open={isComponentVisible}
        onClose={() => setIsComponentVisible(false)}
      >
        <div className='flex absolute flex-col gap-y-[20px] right-[8px]'>
          <a
            href={systemConfig?.companyZalo}
            target='_blank'
            className='zalo flex items-center justify-end'
          >
            <span className='mr-[8px] font-bold text-white-400'>Zalo</span>
            <span className='bg-blue-400 p-[15px] rounded-full'>
              <IconZalo />
            </span>
          </a>
          <a
            href={systemConfig?.companyMessenger}
            target='_blank'
            className='messenger flex  items-center justify-end'
          >
            <span className='mr-[8px] font-bold text-white-400'>Messenger</span>
            <IconMessenger />
          </a>
          <a
            href='#nogo'
            onClick={() => (window as any).subiz('expandWidget')}
            className='subiz flex items-center justify-end'
          >
            <span className='mr-[8px] font-bold text-white-400'>Chat ngay</span>
            <IconChat />
          </a>
          <a
            href={`tel:${systemConfig?.companyHotline?.trim()}`}
            className='messenger flex items-center justify-end'
          >
            <p className='mr-[8px] font-bold text-white-400 flex flex-col text-right'>
              <span>Tổng đài</span>
              <span className='font-bold text-white-400'>
                {systemConfig?.companyHotline}
              </span>
            </p>
            <IconHotLine />
          </a>
        </div>
      </DrawChat>
      <div
        onClick={() => setIsComponentVisible(true)}
        className='block z-[999] fixed bottom-[126px] sm:right-[calc(50vw-189px)] sm:translate-x-[-(calc(50vw-189px))] md:right-[calc(50vw-207px)] md:translate-x-[-(calc(50vw-207px))]'
      >
        {isComponentVisible ? <IconChatClose /> : <IconChat />}
      </div>
    </>
  )
}

export default PopupChat
