import { HeaderLogo } from '@/components/HeaderLogo'
import { IconHome } from '@/components/Icon/HeaderIcon'
import ImageOptimize from '@/components/ImageOptimize'
import { PageType } from '@/constants'
import { useAuth, useStore } from '@/containers'
import { useRouterBack } from '@/hooks/useRouterBack'
import {
  IconAccount,
  IconAccountDefault,
  IconBack,
  IconsDownsLocate,
  SearchIcon,
} from '@/styles/Icon'
import Link from 'next/link'
import { useEffect, useMemo, useState } from 'react'
interface HeaderType {
  layout: string
  page: string
  dataHeader: string | undefined
  btnHome?: boolean
}
const Header = ({ layout, dataHeader, btnHome = true, page }: HeaderType) => {
  const handleGoBack = useRouterBack()
  const [scrollY, setScrollY] = useState(0)
  const handleScroll = () => {
    setScrollY(document.documentElement.scrollTop)
  }
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll)
      return () => window.removeEventListener('scroll', handleScroll)
    }
  })
  const { regions, saleRegion, setIsOpenPopupLocation } = useStore()
  const store = regions.find(e => e.code === saleRegion)
  const { user } = useAuth()
  const headerDefault = useMemo(() => {
    return (
      <div className='flex h-[60px] items-center justify-between px-1 w-full'>
        <div className='flex-[40px] max-w-[40px]'>
          <button
            className='h-10 w-10 outline-none flex justify-center items-center'
            onClick={() => handleGoBack(true)}
          >
            <IconBack />
          </button>
        </div>

        <div className='flex-1 w-full px-1'>
          <h1 className='text-headingMedium break-all text-dark-800 overflow-hidden text-ellipsis  line-clamp-1'>
            {dataHeader}
          </h1>
        </div>
        {btnHome ? (
          <div className='flex-[40px] max-w-[40px]'>
            <Link
              className='h-10 w-10 outline-none flex justify-center items-center'
              href={'/'}
            >
              <IconHome />
            </Link>
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }, [dataHeader])
  return (
    <header
      className={`${
        scrollY > 0 && layout == PageType.HOMEPAGE ? 'bg-white' : ''
      } ${dataHeader != PageType.HOMEPAGE ? '' : 'bg-white shadow-100'} ${
        layout == '' ? 'sticky bg-white' : 'fixed left-[50%] translate-x-[-50%]'
      }  top-0 z-[999] flex h-[60px] w-full max-w-[440px] items-center justify-between`}
    >
      {layout == PageType.HOMEPAGE && (
        <div className={`flex w-full items-center justify-between gap-2 px-2`}>
          <div className='relative mr-1 max-w-[40px] flex-[40px]'>
            {page && page == 'homepage' ? (
              <h1 className='block'>
                <HeaderLogo />
              </h1>
            ) : (
              <div className='block'>
                <HeaderLogo />
              </div>
            )}
          </div>
          <Link
            href={'/search'}
            className='flex h-[40px] w-full flex-1 items-center justify-between gap-2 overflow-hidden rounded-full bg-white-500 py-2 pl-3 pr-4'
          >
            <SearchIcon />
            <span className='flex-1 text-xs text-gray-900'>
              Bạn cần tìm gì hôm nay?
            </span>
          </Link>

          <Link
            className='account relative max-w-[36px] flex-[0_0_36px]'
            href='/account'
          >
            <div
              className={`avt rounded-full overflow-hidden p-[2px] w-[36px] h-[36px] flex items-center justify-center mb-2 mt-3 ${
                user ? 'bg-white-400/80 ' : ''
              }`}
            >
              {user?.avatarImage && (
                <ImageOptimize
                  src={user?.avatarImage}
                  alt={user?.name}
                  priority
                  width={200}
                  height={200}
                  className='aspect-square object-cover w-full rounded-full'
                />
              )}
              {user ? <IconAccountDefault /> : <IconAccount />}
            </div>
            {/* {user && (
              <div className='w-4 h-4 text-white text-[9px] flex justify-center items-center font-medium bg-red-600 rounded-full absolute top-2 right-[-2px]'>
                9+
              </div>
            )} */}
          </Link>
          <div className='max-w-[60px] flex-[0_0_60px]'>
            <div
              className='flex items-center cursor-pointer'
              onClick={() => setIsOpenPopupLocation(true)}
            >
              <span className='line-clamp-2 text-xs leading-tight w-[52px]'>
                {store?.name}
              </span>
              <IconsDownsLocate />
            </div>
          </div>
        </div>
      )}

      {layout == '' && headerDefault}
    </header>
  )
}
export { Header }
