interface IconHotLineProps {
  className?: string
}
export const IconHotLine = ({ className }: IconHotLineProps) => {
  return (
    <svg
      className={className}
      width='60'
      height='61'
      viewBox='0 0 60 61'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='30.0024' cy='30.7939' r='30' fill='#FF0000' />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M30.5486 13.3351C27.1374 13.2239 23.691 14.1352 20.7156 16.0799C17.3737 18.2665 15.0382 21.4954 13.9625 25.105C13.6654 25.0695 13.3097 25.0851 12.8873 25.2021C11.3102 25.6402 10.1241 26.9056 9.59076 28.02C8.89903 29.4723 8.60729 31.393 8.91503 33.3759C9.22063 35.3525 10.0734 36.9819 11.1401 37.9934C12.21 39.0055 13.418 39.3345 14.618 39.0875C16.4046 38.7141 17.2905 38.4347 17.0404 36.8048L15.8292 28.9041C16.0734 24.6282 18.3342 20.6029 22.1156 18.1276C27.1769 14.8172 33.8126 15.0292 38.6404 18.6581C41.9988 21.1794 43.9486 24.9572 44.1737 28.9229L43.3268 34.4496C41.4377 39.5115 36.7545 43.0177 31.3353 43.5154H27.7268C26.7956 43.5154 26.0457 44.2496 26.0457 45.1604V46.0283C26.0457 46.9396 26.7956 47.6738 27.7268 47.6738H32.2766C33.2073 47.6738 33.954 46.9396 33.954 46.0283V45.5745C38.0404 44.598 41.6073 42.1388 43.93 38.7094L45.3876 39.088C46.5737 39.3893 47.7961 39.0055 48.8654 37.9939C49.9321 36.9819 50.7844 35.3531 51.0905 33.3764C51.3993 31.3936 51.099 29.476 50.4153 28.0205C49.7289 26.5651 48.7065 25.6408 47.5262 25.3092C47.0318 25.1697 46.4953 25.1185 46.035 25.105C45.0622 21.8411 43.0596 18.8728 40.1732 16.7055C37.3353 14.5733 33.9598 13.4448 30.5486 13.3351Z'
        fill='white'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M35.874 27.549C37.0766 27.549 38.0516 28.5036 38.0542 29.6843C38.0516 30.8619 37.0766 31.8192 35.874 31.8192C34.6681 31.8192 33.6905 30.8619 33.6905 29.6843C33.6905 28.5041 34.6686 27.549 35.874 27.549ZM30.002 27.549C31.2073 27.549 32.1822 28.5036 32.1822 29.6843C32.1822 30.8619 31.2073 31.8192 30.002 31.8192C28.7956 31.8192 27.8206 30.8619 27.8206 29.6843C27.8206 28.5041 28.7956 27.549 30.002 27.549ZM24.1321 27.549C25.3348 27.549 26.3124 28.5036 26.3124 29.6843C26.3124 30.8619 25.3348 31.8192 24.1321 31.8192C22.9268 31.8192 21.9513 30.8619 21.9513 29.6843C21.9513 28.5041 22.9268 27.549 24.1321 27.549ZM30.002 18.3777C23.6073 18.3777 18.4542 23.2573 18.4542 29.6843C18.4542 32.7712 19.6462 35.4993 21.5881 37.5009L20.899 40.5257C20.6718 41.521 21.3769 42.1905 22.2905 41.6923L25.307 40.0447C26.7406 40.6541 28.3262 40.991 30.002 40.991C36.3988 40.991 41.5486 36.1144 41.5486 29.6843C41.5486 23.2573 36.3988 18.3777 30.002 18.3777Z'
        fill='white'
      />
    </svg>
  )
}
